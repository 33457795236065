import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { BackendService } from '@core/services/backend.service';

@Component({
  selector: 'se-multiSelect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectComponent {
  @Input() dropdownValues: any[];
  @Input() preSelected: string[];
  @Input() isDisabled: boolean;
  @Input() colId: string;
  @Input() rowData: string[];

  @Output() changedSelection = new EventEmitter<any>();

  selectedValues: string[];

  constructor(
    private backendService: BackendService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.preSelected = this.preSelected || [];

    if (this.dropdownValues) {
      this.selectedValues = this.preSelected;
      if (this.preSelected) {
        this.selectedValues = this.dropdownValues
          .filter((item) => this.preSelected.includes(item.value))
          .map((item) => item.value);
      }
    } else if (
      this.colId === 'LINKED_RISKS' &&
      this.rowData['ID_UNIQUE_RISK']
    ) {
      this.selectedValues = [
        ...this.preSelected.filter((value) => value !== ''),
        this.rowData['ID_UNIQUE_RISK'],
      ];
    }
  }

  fetchDropdownValues() {
    if (this.colId === 'LINKED_RISKS' && this.rowData['IFA_NUMBER']) {
      this.backendService
        .getDropdownRiskIds(this.rowData['IFA_NUMBER'])
        .subscribe((data) => {
          console.log('data: ', data);
          this.dropdownValues = data.map((item) => ({
            value: item,
            disabled: item === this.rowData['ID_UNIQUE_RISK'],
          }));
        });
    }
  }

  /**
   * Emits the current selection of values.
   */
  saveSelection() {
    let transformedValues =
      this.selectedValues.length === 0 ? [''] : this.selectedValues;

    // Filter out the value matching this.rowData['ID_UNIQUE_RISK'] if colId is 'LINKED_RISKS'
    if (this.colId === 'LINKED_RISKS') {
      transformedValues = transformedValues.filter(
        (value) => value !== this.rowData['ID_UNIQUE_RISK']
      );
    }

    this.changedSelection.emit(transformedValues);
  }

  /**
   * Clears the current selection and emits the change.
   */
  clearSelection() {
    this.selectedValues = [''];
    this.saveSelection();
  }

  getSelectedValues(): string {
    return (this.selectedValues || []).map((value) => value).join(', ');
  }

  /**
   * Checks if dropdownValues is empty and calls fetchDropdownValues if true.
   */
  checkAndFetchDropdownValues() {
    if (!this.dropdownValues || this.dropdownValues.length === 0) {
      this.fetchDropdownValues();
    }
  }

  onSelectionChange(event: any) {
    let noOtherRisk = 'NO OTHER RISK';
    if (
      this.colId === 'RISK_OTH_INC_GEOPOLIT' &&
      this.selectedValues.includes(noOtherRisk) &&
      this.selectedValues.length > 1
    ) {
      let customMessage = '';

      if (event.itemValue.value === noOtherRisk) {
        customMessage =
          "'NO OTHER RISKS' can't be selected with other options. If you proceed, it will be the only selected value.";
      } else {
        customMessage =
          "Risks can't be selected with 'NO OTHER RISK'. If you proceed, 'NO OTHER RISK' will be deselected.";
      }

      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: customMessage,
        header: 'Warning',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          if (event.itemValue.value === noOtherRisk) {
            // If the current item is "NO OTHER RISK", make it the only selected value
            this.selectedValues = [noOtherRisk];
          } else {
            // Otherwise, remove "NO OTHER RISK" from the selected values
            this.selectedValues = this.selectedValues.filter(
              (value) => value !== noOtherRisk
            );
          }
          this.saveSelection();
        },
        reject: () => {
          this.selectedValues = this.selectedValues.filter(
            (value) => value !== event.itemValue.value
          );
        },
      });
    }
  }
}
