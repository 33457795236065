<div class="dialog-bulk-edit-history container">
  <p *ngIf="loading">Loading...</p>
  <p *ngIf="!loading && bulkEditHistory.length === 0">
    No bulk edits to show in the last 24h.
  </p>
  <p-table
    *ngIf="!loading && bulkEditHistory.length > 0"
    [value]="bulkEditHistory"
    styleClass="p-datatable-striped p-datatable-sm"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="RISK_DB_KEY">Column</th>
        <th pSortableColumn="RISK_DB_VALUE">Risk Value</th>

        <th>Type</th>
        <th>Row Count</th>
        <th>Status</th>
        <th>User</th>
        <th>Time</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th>
          <p-columnFilter
            type="text"
            field="USER_GID"
            placeholder="Search by user id"
            ariaLabel="Filter User"
          ></p-columnFilter>
        </th>
        <th></th></tr
    ></ng-template>

    <ng-template pTemplate="body" let-edit>
      <tr>
        <td>{{ edit.RISK_DB_KEY }}</td>
        <td>
          {{
            edit.TRANSACTION_TYPE === "UNDO"
              ? "previous values"
              : edit.RISK_VALUE
          }}
        </td>
        <td>
          {{ edit.TRANSACTION_TYPE }}
        </td>
        <td>
          {{ edit.R_COUNT }}
        </td>
        <td>
          <p-tag [value]="edit.STATUS" [severity]="getSeverity(edit.STATUS)" />
        </td>
        <td>{{ edit.USER_GID }}</td>
        <td>{{ edit.IMPORT_TIMESTAMP | date : "short" }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
