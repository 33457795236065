import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BackendService } from '@core/services/backend.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bulk-edit-history',
  templateUrl: './dialog-bulk-edit-history.component.html',
  styleUrls: ['./dialog-bulk-edit-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogBulkEditHistoryComponent implements OnInit {
  bulkEditHistory: any[] = [];
  loading: boolean = true;

  constructor(private backendService: BackendService) {}

  ngOnInit(): void {
    this.backendService.getBulkEditHistory().subscribe((response: any) => {
      this.bulkEditHistory = response;
      this.loading = false;
    });
  }

  getSeverity(status: string) {
    switch (status) {
      case 'SUCCESS':
        return 'success';
      case 'INSUCCESS':
        return 'danger';
      default:
        return 'warning';
    }
  }
}
