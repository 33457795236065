const limits = {
  MEASURE_DESCRIPTION: 1000,
  MEASURE_RESPONSIBLE: 300,
  SUPPORT: 300,
  RISK_COMMENT: 2000,
  LINK_TO_MEASURE_PLAN: 300,
  MGMT_DECIDED_BY: 100,
  MGMT_COMMENT: 2000,
  COMMENTS: 1000,
};

export default limits;
