import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  forkJoin,
  throwError,
  Subject,
  switchMap,
  of,
  take,
  Subscription,
} from 'rxjs';

// models for data
import { RisksFilter } from '@core/models/risks-filter.model';

import { TableHeader, TableHeaderGroup } from '@shared/models/table.model';

import { environment } from '@environments/environment';

import { catchError, filter, finalize, map, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { MsalService } from '@azure/msal-angular';
import { VersionService } from '@core/services/VersioningService';
import { UserFeatures } from '@shared/models/user-features.model';
import { getFirstWord } from '@shared/helpers/string-transformation';

@Injectable({
  providedIn: 'root',
})

/**
 * Service for handling backend API interactions.
 */
export class BackendService implements OnDestroy {
  private BASE_URL = environment.apiUrl;
  private APPSTOREID = environment.appStoreId;
  // endpoint names
  private ENDPOINT_HEADERS = 'headers';
  private ENDPOINT_FILTERS = 'filters';
  private ENDPOINT_RELEVANT_FILTERS = 'relevant_filters';
  private ENDPOINT_DROPDOWNS = 'dropdowns';
  private ENDPOINT_RISKS = 'risks';
  private ENDPOINT_SUPORTING_INFO = 'supporting_info';
  private ENDPOINT_METADATA = 'metadata';
  private ENDPOINT_RISKS_COMMENT = 'comment';
  private ENDPOINT_RISKS_TOTALS = 'totals';
  private ENDPOINT_EDIT_RISKS = 'edit';
  private ENDPOINT_ADD_ACTION_PLAN = 'add_actions';
  private ENDPOINT_GET_ACTION_PLAN = 'get_actions';
  private ENDPOINT_ACTION_PLAN_TOTALS = 'actions_totals';
  private ENDPOINT_EDIT_ACTION_PLAN = 'edit_actions';
  private ENDPOINT_PERMISSIONS = 'permissions';
  private ENDPOINT_BULK_EDIT_RISKS = 'bulk_edit';
  private ENDPOINT_BULK_EDIT_UNDO = 'undo_bulk_edit';
  private ENDPOINT_BULK_EDIT_PERMISSIONS = 'bulk_row_permissions';
  private ENDPOINT_BULK_EDIT_HISTORY = 'bulk_edit_history';
  private ENDPOINT_DROPDOWNS_RISK_ID = 'unique_risk_id';

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public loadingFiltersSubject = new BehaviorSubject<boolean>(false);
  public loadingFilters$ = this.loadingFiltersSubject.asObservable();

  private errorSubject = new BehaviorSubject<string>('');
  error$ = this.errorSubject.asObservable();

  private worker: Worker | null = null;
  private workerResponse$ = new Subject<any>();
  private workerSubscription: Subscription;

  private initializeWorker() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('../../risk-worker.worker', import.meta.url)
      );

      // Listen to messages from the worker
      this.worker.onmessage = ({ data }: { data: any }) => {
        if (data.error) {
          this.workerResponse$.error(data.error);
        } else {
          this.workerResponse$.next(data);
        }
      };

      // Handle worker errors
      this.worker.onerror = (error) => {
        this.workerResponse$.error(error);
      };
    }
  }

  /**
   * @param authService - Service for authentication.
   * @param msalService - Service for Microsoft authentication.
   * @param http - HttpClient for making HTTP requests.
   */
  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private versioningService: VersionService,
    private http: HttpClient
  ) {
    this.initializeWorker();
    console.log('Backend Service initialized...');
  }

  /*  ----------------------------------------------------------------------
                MAIN METHODS FOR GETTING DATA FROM THE BACKEND
      ---------------------------------------------------------------------- */

  /**
   * Performs an HTTP POST request.
   *
   * @param relativeUrl - The relative URL to append to the base URL for the request.
   * @param body - The request payload (default is an empty object).
   * @returns An observable emitting the response data.
   */
  httpPost(relativeUrl: string, body: any = {}): Observable<any> {
    return this.versioningService.isLoading$.pipe(
      filter((isLoading) => {
        return !isLoading;
      }),
      take(1), // Only proceed on the first "false" emission
      switchMap(() =>
        this.http.post<any>(`${this.BASE_URL}${relativeUrl}`, body, {})
      )
    );
  }

  /**
   * Performs an HTTP GET request.
   *
   * @param relativeUrl - The relative URL to append to the base URL for the request.
   * @returns An observable emitting the response data.
   */
  httpGet(relativeUrl: string): Observable<any> {
    return this.versioningService.isLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() => this.http.get<any>(`${this.BASE_URL}${relativeUrl}`, {}))
    );
  }

  /*  ----------------------------------------------------------------------
                 API FOR SUPPLIER PERFORMANCE AND RISK EVALUATION
      ---------------------------------------------------------------------- */

  // || ------------------------------ /HEADERS ---------------------------------- ||

  /**
   * Retrieves and formats table headers, header groups, and filters for a given screen.
   *
   * @param screenName - The name of the screen for which to retrieve table data.
   * @returns An observable emitting an object containing:
   *   - `tableHeaders`: Array of table header objects.
   *   - `tableHeaderGroups`: Array of header group objects.
   *   - `tableFilters`: Object mapping filter keys to their values.
   */
  getTableHeaders(screenName): Observable<{
    tableHeaders: TableHeader[];
    tableHeaderGroups: TableHeaderGroup[];
    tableFilters;
    filtersParameters;
  }> {
    let tableHeaders: TableHeader[] = [];
    let tableHeaderGroups: TableHeaderGroup[] = [];
    let tableFilters = {};
    let filtersParameters = {};

    // table headers names
    const tableHeadersName = `${screenName}TableHeaders`;
    const tableHeadersGroupName = `${screenName}TableHeadersGroup`;
    const tableFiltersName = `${screenName}TableFilters`;
    const filtersParametersName = `${screenName}TableFiltersParameters`;

    // get local storage items
    const storedHeaders = sessionStorage.getItem(tableHeadersName);
    const storedHeadersGroup = sessionStorage.getItem(tableHeadersGroupName);
    const storedFilters = sessionStorage.getItem(tableFiltersName);
    const storedFiltersParameters = sessionStorage.getItem(
      filtersParametersName
    );

    if (
      storedHeaders &&
      storedHeadersGroup &&
      storedFilters &&
      storedFiltersParameters
    ) {
      const headers: {
        tableHeaders;
        tableHeaderGroups;
        tableFilters;
        filtersParameters;
      } = {
        tableHeaders: JSON.parse(storedHeaders),
        tableHeaderGroups: JSON.parse(storedHeadersGroup),
        tableFilters: JSON.parse(storedFilters),
        filtersParameters: JSON.parse(storedFiltersParameters),
      };
      return new Observable((observer) => {
        observer.next(headers);
        observer.complete();
      });
    }

    let body = {
      SCREEN_NAME: screenName,
    };

    return this.httpPost(this.ENDPOINT_HEADERS, body).pipe(
      map((response) => {
        return response
          .filter((item) => item.IS_VISIBLE === 1)
          .sort((a, b) => a.RISK_ORDER - b.RISK_ORDER);
      }),
      map((items) => {
        items.forEach((item) => {
          tableHeaders.push({
            id: item.RISK_DB_KEY,
            title: item.RISK_NAME,
            tooltip:
              item.RISK_TOOLTIP !== 'None' && item.RISK_TOOLTIP !== null
                ? item.RISK_TOOLTIP
                : null,
            valueType: this.changeType(
              item.RISK_CALCULATION_TYPE,
              item.RISK_DATATYPE,
              item.RISK_NAME
            ),
            rightSeparator: item.RISK_RIGHT_SEPARATOR,
            background: item.RISK_BACKGROUND,
            isSortable: item.SORTING_POSSIBLE,
            isFiltered: item.FILTERING_POSSIBLE,
            isEditable: item.IS_EDITABLE ? true : false,
            dataType: item.RISK_DATATYPE,
            select_all_possible: !!item.SELECT_ALL_POSSIBLE,
            hasSupportingInfo: false,
            usedInCalculations: item.USED_IN_CALCULATIONS,
            catalogBK: item.RISK_CATALOG_BK,
            minWidth: !this.checkIfTypeIsDropdown(item.RISK_CALCULATION_TYPE),
          });

          // create filters object
          if (item.FILTERING_POSSIBLE) {
            if (item.RISK_DATATYPE === 'Numeric') {
              const prefix = getFirstWord(item.RISK_DB_KEY);
              tableFilters[`${prefix}_LOWER_LIMIT`] = null;
              tableFilters[`${prefix}_UPPER_LIMIT`] = null;
            } else {
              tableFilters[item.RISK_DB_KEY] = null;
              filtersParameters[item.RISK_DB_KEY] = null;
            }
          }

          const categoryIndex = tableHeaderGroups.findIndex(
            (category) => category.title === item.RISK_CATEGORY
          );

          if (categoryIndex === -1) {
            // Category not found, create a new object
            tableHeaderGroups.push({
              id:
                'header-row-' +
                item.RISK_CATEGORY.replace(/\s+/g, '-').toLowerCase(),
              title: item.RISK_CATEGORY,
              colspan: 1,
            });
          } else {
            // Category found, increment colspan
            tableHeaderGroups[categoryIndex].colspan++;
          }
        });

        sessionStorage.setItem(tableHeadersName, JSON.stringify(tableHeaders));
        sessionStorage.setItem(
          tableHeadersGroupName,
          JSON.stringify(tableHeaderGroups)
        );
        sessionStorage.setItem(tableFiltersName, JSON.stringify(tableFilters));
        sessionStorage.setItem(
          filtersParametersName,
          JSON.stringify(filtersParameters)
        );

        return {
          tableHeaders,
          tableHeaderGroups,
          tableFilters,
          filtersParameters,
        };
      })
    );
  }

  // || ------------------------------ ACTION PLANS POPUP ---------------------------------- ||

  /**
   * Retrieves and transforms fields for the action plan popup.
   *
   * @returns An observable that emits an array of categories with fields for the popup.
   */
  getFieldsForActionPlanPopup(): Observable<any[]> {
    const body = {
      SCREEN_NAME: 'pop_up',
    };

    return this.httpPost(this.ENDPOINT_HEADERS, body).pipe(
      map((data: any[]) => {
        // Sort data based on RISK_ORDER
        data.sort((a, b) => a.RISK_ORDER - b.RISK_ORDER);

        const transformedData: any[] = [];

        const groupedData = data.reduce((result, item) => {
          if (!result[item.RISK_CATEGORY]) {
            result[item.RISK_CATEGORY] = [];
          }
          result[item.RISK_CATEGORY].push(item);
          return result;
        }, {});

        for (const category in groupedData) {
          if (groupedData.hasOwnProperty(category)) {
            const fields = groupedData[category];
            transformedData.push({
              categoryTitle: category,
              values: fields.map((field) => ({
                id: field.RISK_DB_KEY,
                name: field.RISK_NAME,
                valueType: field.RISK_CALCULATION_TYPE,
                isEditable: field.IS_EDITABLE,
                isMandatory: field.IS_MANDATORY,
                isVisible: field.IS_VISIBLE,
                dataType: field.RISK_DATATYPE,
              })),
            });
          }
        }

        return transformedData;
      })
    );
  }

  // || ------------------------------ /RISKS (DATA) ---------------------------------- ||

  /**
   * Handles errors by logging them and updating UI states.
   *
   * @param error The error to handle.
   * @returns An observable that throws the error.
   */
  handleError(error): Observable<never> {
    console.error('Error retrieving risks data:', error);
    this.setLoading(false);
    this.loadingSubject.next(false);
    this.errorSubject.next('An error occurred while retrieving data on risks');
    return throwError(error);
  }

  /**
   * Asynchronously retrieves an authentication token for the current user.
   *
   * @returns A promise that resolves to the access token.
   */
  async getAuthToken() {
    const account = this.msalService.instance.getAllAccounts()[0];
    const tokenResponse = await this.msalService.instance.acquireTokenSilent({
      scopes: [...environment.azureAD.apiConfig.scopes],
      account: account,
    });

    return tokenResponse.accessToken;
  }

  getMetadata(screenName: string): Observable<any> {
    return this.httpPost(this.ENDPOINT_METADATA, {
      SCREEN_NAME: screenName,
    });
  }

  /**
   * Retrieves risks data and applies edit permissions based on provided filter and permissions.
   * Utilizes a web worker for processing if supported; otherwise, uses HTTP POST.
   *
   * @param filter - The filter criteria to apply to the risks data.
   * @param permissions - Permissions used to determine if rows can be edited.
   * @returns An `Observable` containing the processed risks data.
   */
  getRisksTableData(filter: RisksFilter, permissions: any): Observable<any[]> {
    this.setLoading(true);

    return new Observable((observer) => {
      this.getAuthToken().then((token) => {
        if (this.worker) {
          this.worker.postMessage({
            token,
            url: `${this.BASE_URL}${this.ENDPOINT_RISKS}`,
            filter: filter,
          });
        }

        if (this.workerSubscription) {
          this.workerSubscription.unsubscribe();
        }

        // Subscribe to the worker response
        this.workerSubscription = this.workerResponse$
          .pipe(
            map((data) => this.addCanEditRow(data as any[], permissions)),
            tap(() => this.setLoading(false)),
            catchError((error) => this.handleError(error))
          )
          .subscribe({
            next: (data) => observer.next(data),
            error: (error) => observer.error(error),
            complete: () => observer.complete(),
          });

        // Clean up subscription on observable completion
        return () => this.workerSubscription.unsubscribe();
      });
    });
  }

  // || ------------------------------ /COMMENT - change risks comment ---------------- ||

  /**
   * Submits a comment related to risks.
   *
   * @param body - The request payload containing the comment details.
   * @returns An `Observable` containing the response from the server.
   */
  setComment(body: any): Observable<any[]> {
    return this.httpPost(this.ENDPOINT_RISKS_COMMENT, body);
  }

  /**
   * Retrieves the total number of risks based on the provided filter.
   *
   * @param filter - The filter criteria for fetching risks totals.
   * @returns An `Observable` of the total number of risks.
   */
  getRisksTotals(filter: RisksFilter): Observable<number> {
    return this.httpPost(this.ENDPOINT_RISKS_TOTALS, filter).pipe(
      map((data: any[]) => {
        return data[0].row_count;
      })
    );
  }

  // || ------------------------------ /SUPPORTING_INFO ------------------------------- ||

  /**
   * Retrieves supporting information from the server.
   *
   * @param data - Data to be sent in the POST request.
   * @returns An `Observable` of the server's response containing the supporting information.
   */
  getSupportingInfo(data): Observable<any> {
    return this.httpPost(this.ENDPOINT_SUPORTING_INFO, data);
  }

  // || ------------------------------ /EDIT --------------------------------------- ||

  /**
   * Sends a request to update or edit a risk row with the provided data.
   *
   * @param data - The data to be sent to the server for updating the risk row. This should include all necessary fields for the update.
   * @returns An `Observable` that emits the response from the server after attempting to edit the risk row.
   *
   * This method performs the following steps:
   * 1. Sends an HTTP POST request to the server with the data to be updated.
   * 2. The server's response is returned as an observable.
   */
  editRiskRow(data): Observable<any> {
    return this.httpPost(this.ENDPOINT_EDIT_RISKS, data);
  }

  // || ------------------------------ /BULK_EDIT ---------------------------------- ||
  bulkEditRisks(data): Observable<any> {
    return this.httpPost(this.ENDPOINT_BULK_EDIT_RISKS, data);
  }

  // || ------------------------------ /BULK_EDIT_PERMISSIONS ---------------------------------- ||
  getBulkEditRisksPermissions(data): Observable<any> {
    return this.httpPost(this.ENDPOINT_BULK_EDIT_PERMISSIONS, data);
  }

  // || ------------------------------ /BULK_EDIT_PERMISSIONS ---------------------------------- ||
  bulkEditRisksUndo(data): Observable<any> {
    this.setLoading(true);

    return this.httpPost(this.ENDPOINT_BULK_EDIT_UNDO, data).pipe(
      finalize(() => this.setLoading(false))
    );
  }

  // || ------------------------------ /BULK_EDIT_HISTORY ---------------------------------- ||
  getBulkEditHistory(): Observable<any> {
    return this.httpGet(this.ENDPOINT_BULK_EDIT_HISTORY);
  }

  // || ------------------------------ /FILTERS --------------------------------------- ||

  /**
   * Retrieves filter values for a specific screen and filter keys.
   * First, checks if filter values are available in local storage; if not, it fetches the data from the server.
   * Stores the fetched filter values in session storage to be used later.
   *
   * @param screenName - The name of the screen for which filter values are to be retrieved.
   * @param filterKeys - An object where keys represent filter names and values are the criteria for those filters.
   * @param IFANumber - Optional. A specific identifier related to the filter criteria.
   * @param _resetStorage
   * @param ID_UNIQUE_RISK - Optional. A unique risk identifier for the filters.
   * @returns An `Observable` that emits an object where keys are filter names and values are arrays of filter options (id and label).
   *
   * The method performs the following steps:
   * 1. Checks if the filter values are available in local storage and not marked for reset.
   * 2. If available, returns the stored filter values.
   * 3. If not available or reset is required, fetches the filter values from the server using HTTP POST requests.
   * 4. Transforms the server response into a suitable format and stores it in session storage.
   * 5. Returns the filter values as an observable.
   */
  getFilterValues(
    screenName: string,
    filterKeys: { [key: string]: any },
    IFANumber: string = '',
    _resetStorage = false,
    ID_UNIQUE_RISK: string = ''
  ): Observable<{ [key: string]: any }> {
    this.loadingFiltersSubject.next(true);

    const tableFilters: { [key: string]: any } = {};

    const keys = Object.keys(filterKeys);

    const observables = keys.map((key) => {
      const body = {
        RISK_DB_KEY: key,
        SCREEN_NAME: screenName,
        IFA_NUMBER: IFANumber,
        ID_UNIQUE_RISK: ID_UNIQUE_RISK,
      };

      return this.httpPost(this.ENDPOINT_FILTERS, body).pipe(
        catchError((error) => {
          console.error(`Error for key ${key}:`, error);
          return of([]); // Return an empty array to allow forkJoin to proceed
        })
      );
    });

    return forkJoin(observables).pipe(
      map((apiResults) => {
        apiResults.forEach((apiResult, index) => {
          // transform into array of values for multiselect
          const transformedValues = Array.isArray(apiResult)
            ? apiResult.map((value, subIndex) => ({
                id: subIndex + 1,
                label: value,
              }))
            : [];

          tableFilters[keys[index]] = transformedValues;
        });

        this.loadingFiltersSubject.next(false);
        return tableFilters;
      })
    );
  }

  getRelevantFiltersValues(
    colId: string,
    filtersKeys: { [key: string]: any },
    screenName: string
  ): Observable<{ [key: string]: any }> {
    this.loadingSubject.next(true);
    const args = [
      'BUSINESS_AREA',
      'SUPPLIER_NAME',
      'SEGMENT_NAME',
      'LOCATION_NAME',
      'BUSINESS_AREA',
      'EXECUTION_UNIT',
    ];
    const keys = Object.keys(filtersKeys).filter((key) => {
      return args.includes(key) && filtersKeys[key];
    });

    const params = keys.reduce((result, key) => {
      result[key] = filtersKeys[key];
      return result;
    }, {});

    return this.httpPost(this.ENDPOINT_RELEVANT_FILTERS, {
      RISK_DB_KEY: colId,
      SCREEN_NAME: screenName,
      ...filtersKeys,
    }).pipe(
      map((apiResult) => {
        // assuming apiResult is your array
        this.loadingSubject.next(false);
        return apiResult.map((value, subIndex) => ({
          id: value || subIndex + 1,
          label: value,
        }));
      })
    );
  }

  // || ------------------------------ /DROPDOWNS ---------------------------------- ||

  /**
   * Retrieves dropdown values for a specific screen by sending the screen name to the server.
   * Sends a POST request with the screen name to fetch the dropdown values and their associated colors.
   *
   * @param screenName - The name of the screen for which dropdown values are to be retrieved.
   * @returns An `Observable` that emits an object where keys are dropdown values and values are arrays of dropdown details (value and color).
   *
   * The method processes the response to create a map of dropdown values and their details,
   * then transforms this map into an object where each key is a dropdown value and its value is an array of objects containing dropdown details.
   */
  getDropdownsValues(screenName): Observable<any> {
    const body = {
      SCREEN_NAME: screenName,
    };
    this.setLoading(true);

    return this.httpPost(this.ENDPOINT_DROPDOWNS, body).pipe(
      map((response) => {
        const dropdownsMap = new Map<string, any>();

        response.forEach((curr) => {
          const value = curr.RISK_DB_KEY;
          const dropdown = {
            value:
              curr.RISK_DROPDOWN_VALUE !== null ? curr.RISK_DROPDOWN_VALUE : '',
            color: curr.RISK_DROPDOWN_COLOR.replace(/\s+/g, '-'),
          };

          if (dropdownsMap.has(value)) {
            dropdownsMap.get(value).dropdowns.push(dropdown);
          } else {
            dropdownsMap.set(value, { value, dropdowns: [dropdown] });
          }
        });

        const transformedData = {};
        dropdownsMap.forEach((value, key) => {
          transformedData[key] = value.dropdowns.map((dropdown) => ({
            value: dropdown.value,
            color: dropdown.color,
          }));
        });
        this.setLoading(false);

        return transformedData;
      })
    );
  }

  // || ------------------------------ /ADD_ACTIONS ---------------------------------- ||

  /**
   * Adds a new action plan by sending the provided data to the server.
   * Sends a POST request with the given body to create a new action plan.
   *
   * @param body - The data to be sent in the request body, which includes the details of the new action plan.
   * @returns An `Observable` that emits the primary key (`ACTION_PLAN_ID_PK`) of the newly created action plan.
   *
   * The method maps the response to extract and return the `ACTION_PLAN_ID_PK` from the data array.
   */
  addActionPlan(body: any): Observable<any> {
    return this.httpPost(this.ENDPOINT_ADD_ACTION_PLAN, body).pipe(
      map((data: any[]) => {
        return data[0]['ACTION_PLAN_ID_PK'];
      })
    );
  }

  // || ------------------------------ /GET_ACTIONS ---------------------------------- ||

  /**
   * Retrieves action plans table data from the server and applies edit permissions.
   * Sends a POST request with the provided body to get the action plans data,
   * then applies the edit permissions to the retrieved data.
   *
   * @param body - The data to be sent in the request body. This typically includes filters or parameters for retrieving action plans.
   * @param permissions - The permissions to be applied to each row in the action plans data.
   * @returns An `Observable` that emits the action plans data with applied edit permissions.
   *
   * The method also manages loading state and error handling:
   * - Sets the loading state to `true` while fetching data.
   * - Sets the loading state to `false` when the data is successfully retrieved or an error occurs.
   * - Emits an error message if data retrieval fails.
   */
  getActionPlansTableData(body: any, permissions: any): Observable<any[]> {
    this.setLoading(true);
    return this.httpPost(this.ENDPOINT_GET_ACTION_PLAN, body).pipe(
      map((data: any[]) => {
        return this.addCanEditRow(data, permissions);
      }),
      tap(() => {
        this.setLoading(false);
      }),
      catchError((error) => {
        console.error('Error retrieving action plans data:', error);
        this.setLoading(false);

        this.loadingSubject.next(false);
        this.errorSubject.next(
          'An error occurred while retrieving data on actions'
        );
        return throwError(error);
      })
    );
  }

  // || ------------------------------ /GET_TOTALS ---------------------------------- ||

  /**
   * Retrieves the total number of action plans from the server.
   * Sends a POST request with the provided body to get the count of action plans.
   *
   * @param body - The data to be sent in the request body. This typically includes filters or parameters for counting action plans.
   * @returns An `Observable` that emits the total count of action plans.
   */
  getActionPlansTotal(body: any): Observable<any> {
    return this.httpPost(this.ENDPOINT_ACTION_PLAN_TOTALS, body).pipe(
      map((data: any[]) => {
        return data[0]['COUNT(*)'];
      })
    );
  }

  // || ------------------------------ /EDIT_ACTIONS ---------------------------------- ||

  /**
   * Submits an action plan to the server.
   * Sends a POST request with the provided body to update or create an action plan.
   *
   * @param body - The data to be sent in the request body. This should contain the details of the action plan.
   * @returns An `Observable` that emits the server's response to the POST request.
   */
  setActionPlan(body: any): Observable<any> {
    return this.httpPost(this.ENDPOINT_EDIT_ACTION_PLAN, body);
  }

  // || ------------------------------ /PERMISSIONS ---------------------------------- ||

  /**
   * Fetches the edit permissions for the current user.
   * Sends a POST request to retrieve permissions based on the user's GID.
   *
   * @returns An `Observable` that emits the user's edit permissions profile,
   *          extracted from the JSON response returned by the server.
   */
  getUserPermissions(): Observable<any> {
    // set body for the request
    const gid = this.authService.getAccountGID();
    const body = {
      GID: gid,
    };

    return this.httpPost(this.ENDPOINT_PERMISSIONS, body).pipe(
      map((data: any) => {
        const permissions = JSON.parse(data[0].PERMISSIONS);
        const userFeatures: UserFeatures = {
          bulkEdit:
            permissions.Features?.RISK_BULK_EDIT === 'ENABLED' ? true : false,
        };

        return {
          permissions: permissions.Profile,
          userFeatures: userFeatures,
        };
      })
    );
  }

  // || ------------- USAGE ON APPSTORE (https://appstore.edaa.siemens-energy.cloud/apps)  ---------------------------------- ||

  /**
   * Sends a GET request to log application usage access.
   * Constructs the URL with a predefined `appId` and the account GID obtained from `authService`.
   *
   * @returns An `Observable` containing the response from the HTTP GET request.
   *          Returns an empty `Observable` if the GID is not available.
   */

  addUsageAccess(): Observable<any> {
    const gid = this.authService.getAccountGID();
    // set body for the request
    const url =
      'https://store.data.siemens-energy.cloud/api/log?' +
      this.APPSTOREID +
      '&' +
      gid;
    if (gid) {
      return this.http.get<any>(url, {});
    } else {
      return new Observable();
    }
  }

  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    }
  }

  // || ------------------------------ /unique_risk_id ---------------------------------- ||

  getDropdownRiskIds(ifaNumber): Observable<any> {
    const body = {
      IFA_NUMBER: ifaNumber,
    };

    return this.httpPost(this.ENDPOINT_DROPDOWNS_RISK_ID, body).pipe(
      map((response) => response.map((item) => item.ID_UNIQUE_RISK))
    );
  }

  // -------------------- AUXILIARY FUNCTIONS  --------------------

  /**
   * Updates the loading state.
   * @param loading Indicates whether the application is in a loading state.
   */
  private setLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  /**
   * Checks if the given value type is one of the specified types.
   * @param valueType The type to check.
   * @returns `true` if the type is 'Dropdown', 'Formula', 'Multiselect', or 'Freetext'; otherwise, `false`.
   */
  private checkIfTypeIsDropdown(valueType) {
    if (
      valueType === 'Dropdown' ||
      valueType === 'Formula' ||
      valueType === 'Multiselect' ||
      valueType === 'Freetext'
    ) {
      return true;
    }
    return false;
  }

  /**
   * Adjusts the data type based on the value, type, and field name.
   * @param value The current data type.
   * @param type The specified type.
   * @param fieldName The name of the field.
   * @returns Adjusted data type or the original value if no change is needed.
   */
  private changeType(value, type, fieldName) {
    if (
      value === 'Freetext' &&
      type === 'Varchar' &&
      (fieldName.toLowerCase().includes('comment') ||
        fieldName.toLowerCase().includes('description'))
    ) {
      return 'Longtext';
    }

    return value;
  }

  /**
   * Adds an `canEdit` property to each row based on the provided permissions.
   * @param data Array of row data.
   * @param permissions Object containing permission criteria.
   * @returns Array with updated rows including `canEdit` property.
   */
  private addCanEditRow(data: any[], permissions: any): any[] {
    return data.map((row) => {
      const permissionValues = Object.values(permissions);
      const canEdit = permissionValues.every((value, index) => {
        const key = Object.keys(permissions)[index];
        return (
          value === '%' ||
          row[key].toLowerCase() == (value as string).toLowerCase()
        );
      });
      return {
        ...row,
        canEdit,
      };
    });
  }
}
