<div class="table-container">
  <p-table
    #dt
    [value]="tableData"
    [columns]="tableHeaders"
    class="se-table"
    [ngClass]="{ 'checked-class': bulkEditChecked }"
    styleClass="p-datatable-gridlines"
    [rowHover]="true"
    [scrollable]="true"
    scrollHeight="flex"
    [lazy]="true"
    [loading]="loading$ | async"
    (onSort)="customSort($event)"
    stateStorage="session"
    stateKey="state-session-order-{{ tableConfig.screenName }}"
  >
    <!-- *********************************** TOP CAPTION ********************************** -->
    <ng-template pTemplate="caption">
      <div class="se-table__caption">
        <div class="d-flex w-100 align-items-center">
          <div class="se-table__caption-title">
            <span>{{ tableConfig.caption }}</span>
            <span
              class="se-table__caption-title__totals"
              *ngIf="tableData.length > 0 && tableTotalValues > 0"
            >
              <span>
                Displaying <b>{{ tableData.length }} </b></span
              >
              <span
                >of Total <b>{{ tableTotalValues }}</b></span
              >
            </span>
          </div>
          <div>
            <p
              [innerHTML]="tableConfig.textInfo"
              *ngIf="tableConfig.textInfo"
              class="se-table__caption-text"
            >
              {{ tableConfig.textInfo }}
            </p>
          </div>
        </div>
        <div class="d-flex flex-direction-column justify-content-end">
          <div
            *ngIf="tableConfig.screenName !== 'action_plan'"
            class="d-flex align-items-center"
          >
            <div
              *ngIf="bulkEditPermissions?.showButton"
              class="se-table__bulk-edit"
            >
              <span>Bulk edit</span>
              <div class="se-table__bulk-edit__group">
                <div
                  class="se-table__bulk-edit__button"
                  matTooltipPosition="below"
                  matTooltip="Enable/ Disable bulk edit"
                  matTooltipClass="se-table__tooltip"
                >
                  <p-inputSwitch
                    [(ngModel)]="bulkEditChecked"
                    (click)="onBulkEditClick()"
                  />
                </div>
                <div
                  *ngIf="bulkEditChecked"
                  class="se-table__bulk-edit__button"
                  (click)="navigateToHistory()"
                  matTooltipPosition="below"
                  matTooltip="Bulk edit history"
                  matTooltipClass="se-table__tooltip"
                >
                  <fa-icon [icon]="faClock"></fa-icon>
                </div>
                <div
                  *ngIf="bulkEditChecked"
                  class="se-table__bulk-edit__button undo"
                  (click)="onBulkEditUndo()"
                  [ngClass]="{
                    disabled: !bulkEditPermissions.enableUndoButton
                  }"
                  matTooltipPosition="below"
                  matTooltip="Bulk edit undo"
                  matTooltipClass="se-table__tooltip"
                >
                  <fa-icon [icon]="faUndo"></fa-icon>
                </div>
              </div>
            </div>
            <button
              (click)="viewActionsPlans()"
              class="se-button btn btn-sm se-button--secondary"
            >
              <span
                >View action plans
                {{ totalActionPlans && "(" + totalActionPlans + ")" }}</span
              >
            </button>
          </div>
          <div class="d-flex align-items-center">
            <button
              *ngIf="tableConfig.link"
              class="se-button btn btn-sm se-button--secondary"
              [routerLink]="tableConfig.link.url"
            >
              <span>{{ tableConfig.link.label }}</span>
            </button>
            <button
              *ngIf="tableConfig.hasFilters"
              (click)="resetFilterAndOrder()"
              class="se-button btn btn-sm se-button--secondary"
            >
              <span>Reset filters</span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- ************************************ HEADER ************************************** -->
    <ng-template pTemplate="header" let-columns>
      <!-- 1st - header group -->
      <tr>
        <th
          [attr.colspan]="headerGroup.colspan"
          *ngFor="
            let headerGroup of tableHeadersGroup;
            let columnHeaderGroupIndex = index
          "
          class="se-table__header-group"
          [ngClass]="{
            'se-table__header-group--freeze':
              this.tableConfig.nrColumnsFreeze && columnHeaderGroupIndex === 0
          }"
        >
          {{ headerGroup.title }}
        </th>
      </tr>
      <tr>
        <!-- 2nd - header title and sorting -->

        <th
          [pSortableColumn]="col.isSortable ? col.id : null"
          *ngFor="let col of columns; let tableHeaderIndex = index"
          class="se-table__header"
          [ngClass]="
            addClasses(
              col.rightSeparator,
              col.background === 'DARK',
              col.minWidth,
              tableHeaderIndex
            )
          "
        >
          <div class="se-table__header__text">
            <span>{{ col.title }}</span>
            <p-sortIcon *ngIf="col.isSortable" [field]="col.id"></p-sortIcon>
          </div>
        </th>
      </tr>
      <!-- 3th - header more info section -->
      <tr>
        <th
          *ngFor="let col of columns; let tableHeaderIndex = index"
          class="se-table__more-info se-table__header--pt-0"
          [ngClass]="
            addClasses(
              col.rightSeparator,
              col.background === 'DARK',
              col.minWidth,
              tableHeaderIndex
            )
          "
        >
          <div class="se-table__info se-table__bottom-border">
            <div>
              <p></p>
            </div>
            <button
              class="btn btn-sm"
              (click)="togglePanelExpansion(col.id)"
              *ngIf="col.hasSupportingInfo"
            >
              {{ panelExpansionStates[col.id] ? "hide" : "show" }} info
              <fa-icon *ngIf="!panelExpansionStates[col.id]" [icon]="faEye" />
              <fa-icon
                *ngIf="panelExpansionStates[col.id]"
                [icon]="faEyeSlash"
              />
            </button>
            <span
              *ngIf="col.tooltip"
              class="se-table__tooltip"
              matTooltipPosition="below"
              [matTooltip]="col.tooltip"
              matTooltipClass="se-table__tooltip"
            >
              <fa-icon [icon]="faCircleInfo" />
            </span>
          </div>
        </th>
      </tr>
      <!-- 4th - header filter section -->
      <tr *ngIf="(tableFilters | json) != '{}' && tableConfig.hasFilters">
        <th
          *ngFor="let col of columns; let tableHeaderFilterIndex = index"
          class="se-table__filters"
          [ngClass]="
            addClasses(
              col.rightSeparator,
              col.background === 'DARK',
              col.minWidth,
              tableHeaderFilterIndex
            )
          "
        >
          <div
            *ngIf="tableConfig.screenName === 'risk' && col.id === 'actions'"
            class="se-table__body__buttons"
          >
            <button
              (click)="viewActionsPlans()"
              class="se-button btn btn-sm se-button--secondary"
            >
              <span
                >View
                {{ totalActionPlans && "(" + totalActionPlans + ")" }}</span
              >
            </button>
          </div>

          <se-filter-dropdown
            *ngIf="
              col.isFiltered &&
              !(col.valueType === 'DatePicker' || col.dataType === 'Numeric')
            "
            [preSelected]="valuesFiltered[col.id]"
            [valueList]="tableFilters?.[col.id]"
            [hasSelectAll]="col.select_all_possible"
            (fetchRelevantFilters)="fetchValuesForColumn($event, col.id)"
            [isLoading]="loading$ | async"
            (eventFilters)="transformedFilterList($event, col.id)"
          ></se-filter-dropdown>

          <div *ngIf="col.isFiltered && col.valueType === 'DatePicker'">
            <div class="se-table__filter-value d-flex">
              <fa-icon
                [icon]="faFilter"
                (click)="toggleOverlay($event)"
                [ngClass]="{
                  'se-table__filter-value--is-filtering':
                    hasFilterEstimatedDueDates(col.id)
                }"
              ></fa-icon>
            </div>
            <p-overlayPanel
              #opRangeDates
              [dismissable]="false"
              [showCloseIcon]="true"
            >
              <div class="se-table__filter-value__input">
                <p-calendar
                  [(ngModel)]="rangeDates"
                  selectionMode="range"
                  [readonlyInput]="true"
                  [showButtonBar]="true"
                  [inline]="true"
                  class="max-w-full"
                >
                </p-calendar>
              </div>
              <div
                class="d-flex justify-content-between se-table__filter-value__buttons"
              >
                <button
                  class="se-button btn btn-sm se-button--secondary"
                  (click)="resetEstimatedDueDatesValues(col.id)"
                >
                  <span>Clear</span>
                </button>
                <button
                  class="se-button btn btn-sm se-button--primary"
                  (click)="setEstimatedDueDatesFilters(col.id)"
                >
                  <span>Apply</span>
                </button>
              </div>
            </p-overlayPanel>
          </div>

          <!-- CUSTOM FILTER - PVO -->
          <div *ngIf="col.isFiltered && col.dataType === 'Numeric'">
            <div
              (click)="op.toggle($event)"
              class="se-table__filter-value d-flex"
            >
              <fa-icon
                [icon]="faFilter"
                [ngClass]="{
                  'se-table__filter-value--is-filtering': isFilteringNumeric(
                    col.id
                  )
                }"
              ></fa-icon>
            </div>
            <p-overlayPanel #op>
              <div class="se-table__filter-value__input">
                <label>Min Value</label>

                <input
                  class="se-table__body__input"
                  type="number"
                  [(ngModel)]="
                    valuesFiltered[col.id.split('_')[0] + '_LOWER_LIMIT']
                  "
                />
              </div>
              <div class="se-table__filter-value__input">
                <label>Max Value</label>

                <input
                  class="se-table__body__input"
                  type="number"
                  [(ngModel)]="
                    valuesFiltered[col.id.split('_')[0] + '_UPPER_LIMIT']
                  "
                />
              </div>
              <div
                class="d-flex justify-content-between se-table__filter-value__buttons"
              >
                <button
                  class="se-button btn btn-sm se-button--secondary"
                  (click)="resetNumericValues(col.id)"
                >
                  <span>Clear</span>
                </button>
                <button
                  class="se-button btn btn-sm se-button--primary"
                  (click)="setNumericValues(col.id)"
                >
                  <span>Apply</span>
                </button>
              </div>
            </p-overlayPanel>
          </div>
        </th>
      </tr>
    </ng-template>

    <!-- ************************************* BODY *************************************** -->
    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-rowIndex="rowIndex"
    >
      <tr
        [ngClass]="{
          'se-table__updating': rowData.isUpdatingRow,

        }"
        class="se-table__row"
      >
        <ng-container *ngFor="let col of columns; let columnIndex = index">
          <td
            [ngClass]="
              addClasses(
                col.rightSeparator,
                col.background === 'DARK',
                col.minWidth,
                columnIndex,
                col.id
              )
            "
          >
            <!-- 1. dropdowns -->
            <se-dropdown
              *ngIf="
                col.valueType === 'Dropdown' || col.valueType === 'Formula'
              "
              [dropdownValues]="tableDropdowns[col.id]"
              [selectedOption]="rowData[col.id]"
              [isDisabled]="!col.isEditable || !rowData.canEdit"
              [isDarkColor]="col.background === 'DARK'"
              [isTransformEnabled]="
                col.id !== 'STATUS' &&
                col.id !== 'ADJUSTED_RISK_CLASSIFICATION' &&
                col.id !== 'ACTION_ORIGIN' &&
                col.id !== 'GLOBAL_LOCAL_MANAGED'
              "
              (changedOption)="
                updateValue($event, col.id, rowData, rowIndex, col)
              "
            >
            </se-dropdown>

            <!-- 2. multiselect -->
            <se-multiSelect
              *ngIf="col.valueType === 'Multiselect'"
              [dropdownValues]="tableDropdowns[col.id]"
              [preSelected]="rowData[col.id]"
              [isDisabled]="!col.isEditable || !rowData.canEdit"
              [colId]="col.id"
              [rowData]="rowData"
              (changedSelection)="
                updateValue($event, col.id, rowData, rowIndex, col)
              "
            >
            </se-multiSelect>

            <!-- 3. calendar -->
            <se-calendar
              *ngIf="col.valueType === 'DatePicker' && col.isEditable"
              [date]="rowData[col.id]"
              [isDisabled]="!col.isEditable || !rowData.canEdit"
              [defaultDate]="defaultDate"
              (changedDate)="
                updateValue($event, col.id, rowData, rowIndex, col)
              "
            >
            </se-calendar>

            <!-- 4. Input -->
            <input
              *ngIf="col.valueType === 'Freetext' && col.isEditable"
              [type]="col.dataType === 'Integer' ? 'number' : 'text'"
              [(ngModel)]="rowData[col.id]"
              class="se-table__body__input"
              [disabled]="!rowData.canEdit"
              [title]="rowData[col.id]"
              [maxlength]="getFieldLimit(col.id)"
              (change)="
                updateValue(
                  col.dataType === 'Integer'
                    ? getNumericValue(rowData[col.id])
                    : rowData[col.id],
                  col.id,
                  rowData,
                  rowIndex,
                  col
                )
              "
            />

            <!-- 5. Description with pop up -->
            <span
              *ngIf="col.valueType === 'Longtext'"
              class="se-table__body__longtext"
              [ngClass]="{ disabled: !rowData.canEdit }"
              [title]="rowData[col.id]"
              (click)="
                !bulkEditChecked &&
                  rowData.canEdit &&
                  openLongtextDialog(
                    rowData[col.id],
                    col.id,
                    rowData,
                    rowIndex,
                    col
                  )
              "
              >{{ rowData[col.id] }}</span
            >

            <!-- 6. Actions with buttons  -->
            <div
              *ngIf="col.valueType === 'actions'"
              class="se-table__body__buttons"
            >
              <ng-container *ngFor="let btn of col.buttons">
                <button
                  class="se-button btn btn-sm"
                  [ngClass]="
                    btn.type === 'primary'
                      ? 'se-button--primary'
                      : 'se-button--secondary'
                  "
                  *ngIf="
                    (btn.id === 'add' && rowData.canEdit) ||
                    (btn.id === 'view' && rowData.ACTION_PLAN_COUNT)
                  "
                  (click)="rowAction(btn.action, rowData)"
                >
                  <span>
                    {{ btn.text
                    }}<ng-container
                      *ngIf="btn.id === 'view' && rowData.ACTION_PLAN_COUNT"
                    >
                      ({{ rowData.ACTION_PLAN_COUNT }})
                    </ng-container></span
                  >
                </button>
              </ng-container>
            </div>

            <!-- 7. Additional to others - Additional Information accordion -->
            <se-more-info-accordion
              *ngIf="
                rowData.supportingInfo &&
                rowData.supportingInfo[col.id] &&
                panelExpansionStates[col.id]
              "
              [supportingInfo]="rowData.supportingInfo[col.id]"
              [isOpen]="panelExpansionStates[col.id]"
            ></se-more-info-accordion>

            <!-- default display -> not editable -->
            <span
              *ngIf="
                (col.valueType == 'Fixed' ||
                  col.valueType == 'None' ||
                  col.valueType === 'Freetext') &&
                !col.isEditable
              "
              class="se-table__body__text"
              >{{ tranformCellValue(rowData[col.id], col.dataType) }}</span
            >
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <div
    class="se-table__load-more"
    *ngIf="tableConfig.hasLoadMore && tableData.length < tableTotalValues"
  >
    <button (click)="loadMore.emit()" class="se-button se-button--primary btn">
      <span>Load more</span>
    </button>
  </div>
</div>
<p-confirmDialog />
