<p-multiSelect
  #semultiSelect
  [options]="dropdownValues"
  optionLabel="value"
  appendTo="body"
  [(ngModel)]="selectedValues"
  [style]="{ width: '100%' }"
  [panelStyle]="{ width: '300px' }"
  [scrollHeight]="'200px'"
  class="se-multiselect"
  [disabled]="isDisabled"
  (onPanelShow)="checkAndFetchDropdownValues()"
  [title]="getSelectedValues()"
  (onChange)="onSelectionChange($event)"
>
  <ng-template let-selectedItems pTemplate="selectedItems">
    <!-- Check if dropdownValues are loaded -->
    <span
      *ngIf="dropdownValues && dropdownValues.length > 0; else showPreSelected"
    >
      <span *ngIf="selectedItems && selectedItems.length; else emptyLabel">
        <ng-container *ngIf="selectedItems.length === 1; else multipleItems">
          {{ selectedItems[0] }}
        </ng-container>
        <ng-template #multipleItems>
          {{ selectedItems.length }} item(s) selected
        </ng-template>
      </span>
    </span>
    <!-- Show preSelected values if dropdownValues are not loaded -->
    <ng-template #showPreSelected>
      <span
        *ngIf="selectedValues && selectedValues.length > 0; else emptyLabel"
      >
        <ng-container
          *ngIf="selectedValues.length === 1; else multiplePreSelected"
        >
          {{ selectedValues[0] }}
        </ng-container>
        <ng-template #multiplePreSelected>
          {{ selectedValues.length }} item(s) selected
        </ng-template>
      </span>
    </ng-template>
    <ng-template #emptyLabel>
      <span>&nbsp;</span>
      <!-- Display a blank space instead of "empty" -->
    </ng-template>
  </ng-template>
  <p-footer>
    <button
      class="se-button btn btn-sm se-button--secondary"
      (click)="clearSelection(); semultiSelect.hide()"
    >
      <span for="clear-option">Clear</span>
    </button>
    <button
      class="se-button btn btn-sm se-button--primary"
      (click)="saveSelection(); semultiSelect.hide()"
    >
      <span for="saving-option">Save</span>
    </button>
  </p-footer>
</p-multiSelect>
